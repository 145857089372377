<template>
  <!-- 头部 -->
  <div class="header">
    <div class="nav">
      <!-- logo -->
      <router-link to="/" tag="span"><div class="logo"></div></router-link>
      <ul class="nav_list">
        <router-link to="/" tag="li">首页</router-link>
        <router-link to="/function" tag="li">功能</router-link>
        <router-link to="/price" tag="li">价格</router-link>
        <router-link to="/about" tag="li">联系我们</router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header",
}
</script>
<style scoped>
.header {
  height: 150px;
  position: fixed;
  background-color: #fff;
  z-index: 400;
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  width: 1920px;
  padding: 0 200px;
  margin: auto;
}

.logo {
  margin-left: 260px;
  width: 250px;
  height: 76px;
  background: url("../assets/images/header-logo.png") no-repeat 0;
  cursor: pointer;
}

.nav_list {
  font-size: 32px;
  flex: 1;
  display: flex;
  justify-content: space-around;
  line-height: 34px;
  font-family: 'MyFont2', sans-serif;
  margin-right: 170px;

  li {
    cursor: pointer;
  }

  li:hover {
    color: #4879cd;
  }
}
</style>