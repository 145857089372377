<template>
  <div class="footer">
    <div class="contact">
      <router-link to="/" tag="span"><div class="contact_logo"></div></router-link>
      <div class="contact_info">
        <div class="about item">
          <router-link to="/privacypolicy" tag="span">隐私政策</router-link>
          <router-link to="/useragreement" tag="span">用户协议</router-link>
        </div>
        <div class="item"> 邮箱: contact@bacads.com </div>
        <div class="item"> 地址: 武汉东湖新技术开发区光谷大道3号激光工程设计总部二期<br/>研发楼06幢06单元15层5号（K088） </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="copy_info">
      Copyright © 2024 bacads.com 武汉市博安创科技有限公司 版权所有 鄂ICP备2021011398号-1
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer",
}
</script>

<style scoped lang="scss">
/* 底部 */
.footer {
  width: 100%;
  height: 350px;
  overflow: hidden;
  background: #171b1d;
  margin: 0 auto;
}

.divider {
  width: 100%;
  height: 1px;
  border: 1px solid #4c4e5a73;
}

.contact {
  height: 270px;
  display: flex;
  align-items: center;
  margin-left: 430px;
}

/* 底部logo */
.contact_logo {
  margin-right: 50px;
  width: 350px;
  height: 106px;
  background: url("../assets/images/footer-logo.png") no-repeat 0;
  cursor: pointer;
  margin-bottom: 80px;
}

/* 内容 */
.contact_info {
  color: #bfc0c2;
  font-size: 20px;
  .item{
    margin-bottom: 20px;
  }
  .about>span {
    margin-right: 30px;
    cursor: pointer;
  }
}

/* 版权 */
.copy_info {
  height: 80px;
  width: 100%;
  opacity: 0.8;
  text-align: center;
  color: #B4B4B4;
  font-size: 20px;
  line-height: 80px;
}
</style>
