import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);


const router = new VueRouter({
    mode: "history",
    linkExactActiveClass: 'active_exact',
    routes: [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import("../views/About.vue"),
    },
    {
        path: "/price",
        name: "Price",
        component: () =>
            import("../views/Price.vue"),
    },
    {
        path: "/function",
        name: "Function",
        component: () =>
            import("../views/Function.vue"),
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import("../views/Login.vue"),
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import("../views/Register.vue"),
    },
    {
        path: "/useragreement",
        name: "UserAgreement",
        component: () =>
            import("../views/UserAgreement.vue")
    },
    {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: () =>
            import("../views/PrivacyPolicy.vue")
    }
    ]
});

router.afterEach((to, from) => {
    console.log(to, from);
    window.scrollTo(0, 0);
});

export default router;