<template>
  <div id="app">
    <app-header v-if="header_show"></app-header>
    <router-view class="content" v-on:header="header" v-on:footer="footer" />
    <app-footer v-if="footer_show"></app-footer>
    <app-goTop v-if="goTop_show"></app-goTop>
  </div>
</template>

<script>
import "./views/Home.vue";
import header from "./components/header"
import footer from "./components/footer"
import goTop from "./components/goTop"
export default {
  name: "App",
  components: {
    "app-header": header,
    "app-footer": footer,
    "app-goTop": goTop
  },
  data() {
    return {
      header_show: true,
      footer_show: true,
      goTop_show: true,
    }
  },
  methods: {
    header: function (bool) {
      this.header_show = bool;
    },
    footer: function (bool) {
      this.footer_show = bool;
    },
    goTop: function (bool) {
      this.footer_show = bool;
    }
  }
}
</script>
<style lang="scss">
#app {
  max-width: 1920px;
}

.content {
  padding-top: 150px;
}

// 选中的样式
.active_exact {
  color: #4879cd;
}

.t_c {
  text-align: center !important;
}

/* 回到顶部 */
.fixed_tools {
  position: fixed;
  bottom: 27%;
  right: 16px;
  z-index: 1;
}

.fixed_tools_content {
  height: 100px;
}

.single {
  position: relative;
  border-radius: 4px;
  background: linear-gradient(0deg, #bed7ff, #a3c5ff);
  cursor: pointer;
}

.ctr,
.service {
  background: transparent url("./assets/images/top.png") no-repeat center 7px;
  background-size: 28px;
}

.ctr {
  width: 40px;
  height: 40px;
}

.goTop {
  margin-top: 12px;
}

.backTop {
  background: transparent url("./assets/images/top.png") no-repeat center -47px;
}
</style>
