<template>
  <!-- 回到顶部 -->
  <div class="fixed_tools" v-if="goTopShow">
    <div class="fixed_tools_content">
      <div class="single goTop">
        <div class="ctr backTop" @click="backTop"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goTop",
  data() {
    return {
      scrollTop:'',
      goTopShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods:{
    handleScroll(){
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(this.scrollTop > 500) {
        this.goTopShow = true
      } else {
        this.goTopShow = false
      }
    },
    backTop(){
      let timer = setInterval(()=>{
        document.documentElement.scrollTop -=100
        if(document.documentElement.scrollTop <= 0) {
          clearInterval(timer)
        } 
      },10)
    },
  }
};
</script>